<template>
  <div>
    <CRow v-if="sedi !== null && sedi.length > 0">
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <CButton @click="create" type="submit" color="info" class="add">
              Aggiungi Cellulari Sede
            </CButton>
          </CCardHeader>

          <CCardBody v-if="cellularisedi !== null && cellularisedi.length > 0">
            <CCardHeader>
              <div v-for="cellulare in cellularisedi" :key="cellulare.id">
                <CRow>
                  <CCol sm="12">
                    <CCard>
                      <CCardBody>
                        <CRow>
                          <CCol sm="4">
                            <label class="bold-text">Seleziona Sede</label>
                            <v-select
                              label="sedi"
                              :options="sedi.comune"
                              v-model="cellulare.sede"
                              @input="sendEmit"
                            >
                              <template slot="selected-option" slot-scope="option">
                                {{ option.via }}
                              </template>
                              <template slot="option" slot-scope="option">
                                {{ option.via }}
                              </template>
                              <span slot="no-options">Scrivi un nome di un comune</span>
                            </v-select>
                          </CCol>

                          <CCol sm="4">
                            <CInput label="Referente" v-model="cellulare.referente" />
                          </CCol>
                          <CCol sm="4">
                            <CInput label="Cellulare" v-model="cellulare.cellulare" />
                          </CCol>

                          <CCol sm="12">
                            <CButton
                              @click="update(cellulare)"
                              type="submit"
                              color="info"
                              class="save"
                            >
                              <CIcon name="cil-save" />
                              Salva
                            </CButton>

                            <CButton
                              @click="destroy(cellulare)"
                              type="submit"
                              color="danger"
                              class="remove"
                            >
                              <CIcon name="cil-trash" />
                              Rimuovi
                            </CButton>
                          </CCol>
                        </CRow>
                      </CCardBody>
                    </CCard>
                  </CCol>
                </CRow>
              </div>
            </CCardHeader>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'CellulareSedi',
  data: function () {
    return {
      title: 'CellulareSedi',
      select: null,
    };
  },
  computed: {
    ...mapGetters('cellularisedi', ['cellularisedi']),

    ...mapGetters('sedi', ['sedi']),
  },
  methods: {
    sendEmit(value) {
      this.$emit('getComune', value);
    },

    ...mapActions('cellularisedi', ['list', 'create', 'destroy', 'update']),
  },
};
</script>

<style>
.remove-cellulari-azienda {
  margin-left: 12px;
}

.add-cellulari-sede {
  margin-right: 12px;
}

.save-cellulari {
  margin-left: 12px;
}

.salve-cellulari-sedi {
  margin-left: 12px;
}

.save-sedi {
  margin-left: 12px;
}

.remove {
  margin-left: 12px;
}
</style>

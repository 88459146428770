<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <CButton @click="create()" type="submit" color="info" class="add">
              Aggiungi Cellulari Agenzia
            </CButton>
          </CCardHeader>

          <CCardBody v-if="cellulari !== null && cellulari.length > 0">
            <CCardHeader>
              <div v-for="cellulare in cellulari" :key="cellulare.id">
                <CRow>
                  <CCol sm="12">
                    <CCard>
                      <CCardBody>
                        <CRow>
                          <CCol sm="6">
                            <CInput label="Referente" v-model="cellulare.referente" />
                          </CCol>
                          <CCol sm="6">
                            <CInput label="Cellulare" v-model="cellulare.cellulare" />
                          </CCol>

                          <CCol sm="12">
                            <CButton
                              @click="update(cellulare)"
                              type="submit"
                              color="info"
                              class="save"
                            >
                              <CIcon name="cil-save" />
                              Salva
                            </CButton>

                            <CButton
                              @click="destroy(cellulare)"
                              type="submit"
                              color="danger"
                              class="remove"
                            >
                              <CIcon name="cil-trash" />
                              Rimuovi
                            </CButton>
                          </CCol>
                        </CRow>
                      </CCardBody>
                    </CCard>
                  </CCol>
                </CRow>
              </div>
            </CCardHeader>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Cellulare',
  data: function () {
    return {
      title: 'Cellulare',
    };
  },
  computed: {
    ...mapGetters('cellulari', ['cellulari']),
  },
  methods: {
    ...mapActions('cellulari', ['list', 'create', 'destroy', 'update']),
  },
};
</script>

<style>
.remove-cellulari-azienda {
  margin-left: 12px;
}

.add-cellulari-sede {
  margin-right: 12px;
}

.save-cellulari {
  margin-left: 12px;
}

.salve-cellulari-sedi {
  margin-left: 12px;
}

.save-sedi {
  margin-left: 12px;
}

.remove {
  margin-left: 12px;
}
</style>
